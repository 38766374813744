import { unreachable } from "@yume-chan/adb";
import { AdbDaemonWebUsbDevice } from "@yume-chan/adb-daemon-webusb";
import { AdbScrcpyClient, AdbScrcpyOptionsLatest } from "@yume-chan/adb-scrcpy";
import {
  Float32PcmPlayer,
  Float32PlanerPcmPlayer,
  Int16PcmPlayer,
  PcmPlayer,
} from "@yume-chan/pcm-player";
import {
  ScrcpyAudioCodec,
  ScrcpyDeviceMessageType,
  ScrcpyHoverHelper,
  ScrcpyMediaStreamPacket,
  ScrcpyOptionsLatest,
  ScrcpyVideoCodecId,
  clamp,
  h264ParseConfiguration,
  h265ParseConfiguration,
} from "@yume-chan/scrcpy";
import {
  ScrcpyVideoDecoder,
  TinyH264Decoder,
} from "@yume-chan/scrcpy-decoder-tinyh264";
import { WebCodecsDecoder } from "@yume-chan/scrcpy-decoder-webcodecs";
import {
  Consumable,
  ConsumableWritableStream,
  DuplexStreamFactory,
  InspectStream,
  PushReadableStream,
  ReadableStream,
  WritableStream,
} from "@yume-chan/stream-extra";
import { action, autorun, makeAutoObservable, runInAction } from "mobx";
import { GLOBAL_STATE } from "../../state";
import {
  AacDecodeStream,
  CustomOpusDecodeStream,
  OpusDecodeStream,
} from "./audio-decode-stream";
import {
  AoaKeyboardInjector,
  KeyboardInjector,
  ScrcpyKeyboardInjector,
} from "./input";
import { safeReload } from "./reload";
function arrayToStream<T>(array: T[]): ReadableStream<T> {
  return new PushReadableStream(async (controller) => {
    for (const item of array) {
      await controller.enqueue(item);
    }
  });
}
export class ScrcpyPageState {
  running = false;

  fullScreenContainer: HTMLDivElement | null = null;
  rendererContainer: HTMLDivElement | null = null;

  isFullScreen = false;

  logVisible = false;
  log: string[] = [];
  demoModeVisible = false;
  navigationBarVisible = true;

  width = 0;
  height = 0;
  rotation = 0;

  get rotatedWidth() {
    return STATE.rotation & 1 ? STATE.height : STATE.width;
  }
  get rotatedHeight() {
    return STATE.rotation & 1 ? STATE.width : STATE.height;
  }

  client: AdbScrcpyClient | undefined = undefined;
  hoverHelper: ScrcpyHoverHelper | undefined = undefined;
  keyboard: KeyboardInjector | undefined = undefined;
  audioPlayer: PcmPlayer<unknown> | undefined = undefined;
  customMessageChannel: WebSocket | undefined = undefined;

  decoder: ScrcpyVideoDecoder | undefined = undefined;
  fpsCounterIntervalId: any = undefined;
  fps = "0";

  connecting = false;
  connectingTimeout = 0;

  constructor() {
    makeAutoObservable(this, {
      start: false,
      stop: action.bound,
      dispose: action.bound,
      setFullScreenContainer: action.bound,
      setRendererContainer: action.bound,
      clientPositionToDevicePosition: false,
    });

    autorun(() => {
      if (!GLOBAL_STATE.adb) {
        this.dispose();
      }
    });

    if (typeof document === "object") {
      document.addEventListener("fullscreenchange", () => {
        if (!document.fullscreenElement) {
          runInAction(() => {
            this.isFullScreen = false;
          });
        }
      });
    }

    autorun(() => {
      if (this.rendererContainer && this.decoder) {
        while (this.rendererContainer.firstChild) {
          this.rendererContainer.firstChild.remove();
        }
        this.rendererContainer.appendChild(this.decoder.renderer);
      }
    });
  }

  start = async () => {
    try {
      runInAction(() => {
        this.connecting = true;
      });

      localStorage.setItem("nm-lastReloadTime", Date.now().toString());

      const decoderDefinition = WebCodecsDecoder.isSupported()
        ? {
            key: "webcodecs",
            name: "Webcodecs (Hardware)",
            Constructor: WebCodecsDecoder,
          }
        : {
            key: "tinyh264",
            name: "TinyH264 (Software)",
            Constructor: TinyH264Decoder,
          };

      const serial = window.location.hash.replace("#", "");
      const portReq = await fetch(`/connect?id=${serial}`, {
        credentials: "include",
      });

      if (portReq.ok) {
        console.log("Port request successful");
      }

      const { port } = await portReq.json();
      console.log({ port });

      const getWebSocket = () => {
        const socket = new WebSocket(
          `wss://${window.location.hostname}:${port}/websocket?id=${serial}`
        );

        socket.binaryType = "arraybuffer";
        return new Promise<WebSocket>((resolve) => {
          socket.onopen = () => {
            console.log("Connected");
            resolve(socket);
          };
          socket.onclose = () => {
            console.log("Disconnected. Reloading...");
            safeReload();
          };
          socket.onerror = () => {
            console.error("Socket error");
          };
        });
      };

      const createReadableStream = (socket: WebSocket) => {
        return new ReadableStream(
          {
            start: (controller) => {
              socket.onmessage = ({ data }: { data: ArrayBuffer }) => {
                controller.enqueue(new Uint8Array(data));
              };
            },
            cancel: () => {
              socket.close();
            },
          },
          {
            highWaterMark: 16 * 1024,
            size(chunk) {
              return chunk.byteLength;
            },
          }
        );
      };

      const createDuplexStream = async (socket: WebSocket) => {
        const duplex = new DuplexStreamFactory<
          Uint8Array,
          Consumable<Uint8Array>
        >({
          close: () => {
            socket.close();
          },
        });
        socket.onclose = () => {
          duplex.dispose().catch(unreachable);
        };
        const readable = duplex.wrapReadable(createReadableStream(socket));
        const writable = duplex.createWritable(
          new ConsumableWritableStream({
            write(chunk) {
              if (socket.readyState === WebSocket.CLOSED) {
                console.error("Device not plugged-in");
              } else {
                socket.send(chunk);
              }
            },
          })
        );
        return {
          readable,
          writable,
        };
      };

      const videoSocket = await getWebSocket();
      const videoStream = createReadableStream(videoSocket);
      const audioSocket = await getWebSocket();
      const audioStream = createReadableStream(audioSocket);
      const controlSocket = await getWebSocket();
      const controlStream = await createDuplexStream(controlSocket);
      this.customMessageChannel = await getWebSocket();

      videoSocket.addEventListener("error", (e) => {
        console.error("Video socket error", e);
      });

      audioSocket.addEventListener("error", (e) => {
        console.error("Audio socket error", e);
      });

      const client = new AdbScrcpyClient({
        options: new AdbScrcpyOptionsLatest(
          new ScrcpyOptionsLatest({
            clipboardAutosync: true,
          })
        ),
        process: {
          kill: () => {
            videoSocket.close();
            audioSocket.close();
            controlSocket.close();
          },
        } as any,
        stdout: arrayToStream([]),
        videoStream,
        audioStream,
        controlStream,
      });
      client.stdout.pipeTo(
        new WritableStream<string>({
          write: action((line) => {
            this.log.push(line);
          }),
        })
      );

      client.videoStream?.then(({ stream, metadata }) => {
        const decoder = new decoderDefinition.Constructor(metadata.codec);

        const appURL = process.env.NEXT_PUBLIC_WEB_APP_URL;

        if (!appURL) {
          throw new Error("Web app URL is not set");
        }

        window.clearTimeout(this.connectingTimeout);
        window.parent.postMessage(
          {
            type: "RATIO_RESPONSE",
            width: metadata.width,
            height: metadata.height,
          },
          appURL
        );

        console.log("Sent ratio response");

        runInAction(() => {
          this.decoder = decoder;

          let lastFrameRendered = 0;
          let lastFrameSkipped = 0;
          this.fpsCounterIntervalId = setInterval(
            action(() => {
              const deltaRendered = decoder.frameRendered - lastFrameRendered;
              const deltaSkipped = decoder.frameSkipped - lastFrameSkipped;
              // prettier-ignore
              this.fps = `${
                            deltaRendered
                        }${
                            deltaSkipped ? `+${deltaSkipped} skipped` : ""
                        }`;
              lastFrameRendered = decoder.frameRendered;
              lastFrameSkipped = decoder.frameSkipped;
            }),
            1000
          );
        });

        let lastKeyframe = 0n;
        const handler = new InspectStream<ScrcpyMediaStreamPacket>((packet) => {
          if (packet.type === "configuration") {
            let croppedWidth: number;
            let croppedHeight: number;
            switch (metadata.codec) {
              case ScrcpyVideoCodecId.H264:
                ({ croppedWidth, croppedHeight } = h264ParseConfiguration(
                  packet.data
                ));
                break;
              case ScrcpyVideoCodecId.H265:
                ({ croppedWidth, croppedHeight } = h265ParseConfiguration(
                  packet.data
                ));
                break;
              default:
                throw new Error("Codec not supported");
            }

            runInAction(() => {
              // TODO: rotate phone.
              this.log.push(
                `[client] Video size changed: ${croppedWidth}x${croppedHeight}`
              );
              this.width = croppedWidth;
              this.height = croppedHeight;
            });
          } else if (packet.keyframe && packet.pts !== undefined) {
            if (lastKeyframe) {
              const interval = (Number(packet.pts - lastKeyframe) / 1000) | 0;
              runInAction(() => {
                this.log.push(`[client] Keyframe interval: ${interval}ms`);
              });
            }
            lastKeyframe = packet.pts!;
          }
        });

        stream.pipeThrough(handler).pipeTo(decoder.writable);
      });

      const useCustomAudioDecoder = !("AudioDecoder" in window);

      const audioPlayerNumChannel = useCustomAudioDecoder ? 1 : 2;

      client.audioStream?.then(async (metadata) => {
        switch (metadata.type) {
          case "disabled":
            runInAction(() =>
              this.log.push(
                `[client] Demuxer audio: stream explicitly disabled by the device`
              )
            );
            return;
          case "errored":
            runInAction(() =>
              this.log.push(
                `[client] Demuxer audio: stream configuration error on the device`
              )
            );
            return;
          case "success":
            // Code is after this `switch`
            break;
          default:
            throw new Error(
              `Unexpected audio metadata type ${
                metadata["type"] as unknown as string
              }`
            );
        }

        const playbackStream = metadata.stream;
        switch (metadata.codec) {
          case ScrcpyAudioCodec.RAW: {
            const audioPlayer = new Int16PcmPlayer(48000, 2);
            this.audioPlayer = audioPlayer;

            playbackStream.pipeTo(
              new WritableStream({
                write: (chunk) => {
                  audioPlayer.feed(
                    new Int16Array(
                      chunk.data.buffer,
                      chunk.data.byteOffset,
                      chunk.data.byteLength / Int16Array.BYTES_PER_ELEMENT
                    )
                  );
                },
              })
            );

            await this.audioPlayer.start();
            break;
          }
          case ScrcpyAudioCodec.OPUS: {
            const audioPlayer = new Float32PcmPlayer(
              48000,
              audioPlayerNumChannel
            );
            this.audioPlayer = audioPlayer;

            playbackStream
              .pipeThrough(
                useCustomAudioDecoder
                  ? new CustomOpusDecodeStream()
                  : new OpusDecodeStream({
                      codec: metadata.codec.webCodecId,
                      numberOfChannels: 2,
                      sampleRate: 48000,
                    })
              )
              .pipeTo(
                new WritableStream({
                  write: (chunk) => {
                    audioPlayer.feed(chunk);
                  },
                })
              );
            await audioPlayer.start();
            break;
          }
          case ScrcpyAudioCodec.AAC: {
            const audioPlayer = new Float32PlanerPcmPlayer(48000, 2);
            this.audioPlayer = audioPlayer;

            playbackStream
              .pipeThrough(
                new AacDecodeStream({
                  codec: metadata.codec.webCodecId,
                  numberOfChannels: 2,
                  sampleRate: 48000,
                })
              )
              .pipeTo(
                new WritableStream({
                  write: (chunk) => {
                    audioPlayer.feed(chunk);
                  },
                })
              );
            await audioPlayer.start();
            break;
          }
          default:
            throw new Error(
              `Unsupported audio codec ${metadata.codec.optionValue}`
            );
        }
      });

      client.exit?.then(this.dispose);

      client.deviceMessageStream?.pipeTo(
        new WritableStream({
          write(message) {
            switch (message.type) {
              case ScrcpyDeviceMessageType.Clipboard:
                globalThis.navigator.clipboard.writeText(message.content);
                break;
            }
          },
        })
      );

      runInAction(() => {
        this.client = client;
        this.hoverHelper = new ScrcpyHoverHelper();
        this.running = true;
      });

      const device = GLOBAL_STATE.device!;
      if (device instanceof AdbDaemonWebUsbDevice) {
        this.keyboard = await AoaKeyboardInjector.register(device.raw);
      } else {
        this.keyboard = new ScrcpyKeyboardInjector(client);
      }
    } catch (e: any) {
      GLOBAL_STATE.showErrorDialog(e);
    } finally {
      runInAction(() => {
        this.connecting = false;
      });
    }
  };

  async stop() {
    // Request to close client first
    await this.client?.close();
    this.dispose();
  }

  dispose() {
    // Otherwise some packets may still arrive at decoder
    this.decoder?.dispose();
    this.decoder = undefined;

    this.keyboard?.dispose();
    this.keyboard = undefined;

    this.audioPlayer?.stop();
    this.audioPlayer = undefined;

    this.fps = "0";
    clearTimeout(this.fpsCounterIntervalId);

    if (this.isFullScreen) {
      document.exitFullscreen();
      this.isFullScreen = false;
    }

    this.client = undefined;
    this.running = false;
  }

  setFullScreenContainer(element: HTMLDivElement | null) {
    this.fullScreenContainer = element;
  }

  setRendererContainer(element: HTMLDivElement | null) {
    this.rendererContainer = element;
  }

  clientPositionToDevicePosition(clientX: number, clientY: number) {
    const viewRect = this.rendererContainer!.getBoundingClientRect();
    let pointerViewX = clamp((clientX - viewRect.x) / viewRect.width, 0, 1);
    let pointerViewY = clamp((clientY - viewRect.y) / viewRect.height, 0, 1);

    if (this.rotation & 1) {
      [pointerViewX, pointerViewY] = [pointerViewY, pointerViewX];
    }
    switch (this.rotation) {
      case 1:
        pointerViewY = 1 - pointerViewY;
        break;
      case 2:
        pointerViewX = 1 - pointerViewX;
        pointerViewY = 1 - pointerViewY;
        break;
      case 3:
        pointerViewX = 1 - pointerViewX;
        break;
    }

    return {
      x: pointerViewX * this.width,
      y: pointerViewY * this.height,
    };
  }
}

export const STATE = new ScrcpyPageState();
